import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Imgs = () => {
  const attestation = [
    {
      name: "MR ABDELMAJID ALHILAL",
      url: require("../assets/atest/atte (12).jpeg"),
    },
    {
      name: "MR ABDELMALEK MIRASS",
      url: require("../assets/atest/atte (17).jpeg"),
    },
    { name: "MR AMINE KHARAT", url: require("../assets/atest/atte (2).jpeg") },
    {
      name: "MR ANAS KHALIL DARFOUFI",
      url: require("../assets/atest/atte (13).jpeg"),
    },
    { name: "MR AYOUB ACHAACH", url: require("../assets/atest/atte (9).jpeg") },
    {
      name: "MR AYMAN ELGHOUL",
      url: require("../assets/atest/atte (16).jpeg"),
    },
    {
      name: "MR EL BACHIR BEN ELHAJ ALI",
      url: require("../assets/atest/atte (3).jpeg"),
    },
    { name: "MR FADI RAMSIS", url: require("../assets/atest/atte (7).jpeg") },
    {
      name: "MR IBRAHIM DE TURQUIE",
      url: require("../assets/atest/atte (4).jpeg"),
    },
    {
      name: "MR MOHAMED HADI KARMANI",
      url: require("../assets/atest/atte (6).jpeg"),
    },
    {
      name: "MR MOHAMED AMINE HINDI",
      url: require("../assets/atest/atte (15).jpeg"),
    },
    {
      name: "MR MOSTAFA ELOUARDI",
      url: require("../assets/atest/atte (10).jpeg"),
    },
    {
      name: "MR OUALID BOUKHLAL",
      url: require("../assets/atest/atte (19).jpeg"),
    },
    {
      name: "MME OUMAIMA ELHAREM",
      url: require("../assets/atest/atte (14).jpeg"),
    },
    {
      name: "MR OUSSAMA OTHMANE ELKHALIFI",
      url: require("../assets/atest/atte (18).jpeg"),
    },
    { name: "MR RAMI SMIN", url: require("../assets/atest/atte (1).jpeg") },
    {
      name: "MR RAMI HAJ HASSAN",
      url: require("../assets/atest/atte (5).jpeg"),
    },
    { name: "MR SAAD AGID", url: require("../assets/atest/atte (8).jpeg") },
  ];
  const { t } = useTranslation();
  return (
    <>
      <div class="bg-slate-800 text-white md:py-20 pt-20">
        <div className="flex items-center justify-center">
          <div>
            <img
              src={require("../assets/img/fest1.jpeg")}
              alt="."
              className="rounded-3xl object-cover md:h-[400px] "
            />
          </div>
        </div>
        <h1 className="flex flex-col  items-center text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl mt-10">
          {t("Attestations concours de luth")}
        </h1>
        <p className="text-center mb-10 block text-indigo-200">
          {t("Merci de rechercher votre nom et de télécharger votre attestation.")}
        </p>
        <div className="flex flex-col justify-center items-center gap-20">
          <ul className="flex flex-wrap gap-2 items-center justify-center px-4 w-screen">
            {attestation.map((person, idx) => (
              <li key={idx} className="xl:w-[22%] md:w-[30%] w-full">
                <Link
                  className="uppercase py-2 px-3 flex justify-center items-center my-2 bg-orange-500 rounded-lg shadow-lg hover:ring-1 hover:bg-transparent hover:ring-orange-500 hover:text-orange-500 text-white"
                  to={person.url}
                >
                  {t(person.name)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Imgs;
