import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context/AuthContext";
import { Link } from "react-router-dom";
import {
  collection,
  addDoc,
  getDocs,
  query,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { storage, db } from "../firebase-config";
import { v4 } from "uuid";
import { FaTrash } from "react-icons/fa";

const Eventm = () => {
  const { user, logout } = UserAuth();
  const [imageUpload, setImageUpload] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [imageUrls, setImageUrls] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [event, setEvent] = useState([]);
  const imagesListRef = ref(storage, "festivals/");

  const uploadFileAndAddevenement = () => {
    if (imageUpload == null) return;

    const imageRef = ref(storage, `festivals/${imageUpload.name + v4()}`);

    uploadBytes(imageRef, imageUpload)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          const newEvent = {
            name: name,
            description: description,
            imageUrl: url,
          };
          addDoc(collection(db, "Events"), newEvent)
            .then(() => {
              console.log("evenement added to Firestore!");
              setName("");
              setDescription("");
              window.location.reload();
            })
            .catch((error) => {
              console.error("Error adding evenement to Firestore: ", error);
            });
        });
      })
      .catch((error) => {
        console.error("Error uploading image to Storage: ", error);
      });
  };

  useEffect(() => {
    listAll(imagesListRef)
      .then((response) => {
        response.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            setImageUrls((prev) => [...prev, url]);
          });
        });
      })
      .catch((error) => {
        console.error("Error listing images in Storage: ", error);
      });
  }, [imagesListRef]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDocs(query(collection(db, "Events")));
      setEvent(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
    if (event.length > 0) {
      // If there is an event, hide the input fields and display a message
      setName("Event already added");
      setDescription("");
    }
  }, [event.length]);

  const deleteEvent = (eventId) => {
    deleteDoc(doc(db, "Events", eventId))
      .then(() => {
        console.log("Event deleted from Firestore!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting event from Firestore: ", error);
      });
  };

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text max-w-[350px] line-clamp-2">
        {isReadMore ? text.slice(0, 20) : text}
        <button
          onClick={toggleReadMore}
          className="read-or-hide text-zinc-500 hover:text-zinc-300"
        >
          {isReadMore ? "...Plus" : "...<=="}
        </button>
      </p>
    );
  };

  return (
    <div className="pt-20">
      <div class="bg-gray-900 min-h-screen flex items-center justify-center">
        <div class="bg-gray-800 flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 max-w-6xl sm:p-6 sm:my-2 sm:mx-4 sm:rounded-2xl">
          <div class="bg-gray-900 px-2 lg:px-4 py-2 lg:py-10 sm:rounded-xl flex lg:flex-col justify-between">
            <nav class="flex items-center flex-row space-x-2 lg:space-x-0 lg:flex-col lg:space-y-2">
              {/* home */}
              <Link to="/Account">
                <button class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 sm:h-6 sm:w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </button>
              </Link>
              {/* member */}
              <Link to="/Adminmembers">
                <button class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 sm:h-6 sm:w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                  </svg>
                </button>
              </Link>
              {/* events */}
              <Link to="/Events">
                <button class="bg-gray-800 text-white p-4 inline-flex justify-center rounded-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 sm:h-6 sm:w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M15,4h-1V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3v1H5a3,3,0,0,0-3,3v9a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V7A3,3,0,0,0,15,4ZM6,7A1,1,0,0,1,7,6h6a1,1,0,0,1,1,1v2.5H6Zm9,9a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11.5h11Zm0-7H4V8A1,1,0,0,1,5,7h8a1,1,0,0,1,1,1Z" />
                    <path d="M10,13a2,2,0,1,0,2,2A2,2,0,0,0,10,13Zm0,3a1,1,0,1,1,1-1A1,1,0,0,1,10,16Z" />
                  </svg>
                </button>
              </Link>
            </nav>
            <div class="flex items-center flex-row space-x-2 lg:space-x-0 lg:flex-col lg:space-y-2">
              {/* logout */}
              <button
                onClick={logout}
                class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 sm:h-6 sm:w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="flex-1 px-2 sm:px-0">
            <div class="flex justify-between items-center">
              <h3 class="text-3xl font-extralight text-white">
                {user && user.email}
              </h3>
            </div>

            <div>
              <p className="my-5 text-amber-700 font-bold">
                Avec ces champs de saisie, vous pouvez créer le nom et la
                description de votre événement et ajouter une image
                correspondante. Il est important de noter que la hauteur
                maximale de l'image doit être de 300px pour une meilleure
                présentation.
              </p>

              <div className="flex flex-col items-center">
                {event.length === 0 ? (
                  <div className="max-w-md w-full space-y-8">
                    <div className="flex flex-col items-center">
                      <input
                        className="text-sm text-grey-500 mr-5 py-3 px-10 rounded-full border-0 text-md font-semibold text-white bg-gradient-to-r from-blue-600 to-amber-600 hover:cursor-pointer hover:opacity-80"
                        type="file"
                        onChange={(event) => {
                          setImageUpload(event.target.files[0]);
                        }}
                      />
                      <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full border rounded-md px-3 py-2 mt-4 text-sm"
                      />
                      <textarea
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="w-full border rounded-md px-3 py-2 mt-4 text-sm resize-y"
                      />
                      <button
                        className="font-bold mt-2 shadow-md rounded-full bg-gradient-to-r from-indigo-500 via-pink-500 to-yellow-500 hover:from-indigo-600 hover:via-pink-600 hover:to-red-600 px-4 py-3 text-white outline-none focus:ring-4 transform active:scale-x-75 transition-transform mx-5 flex"
                        onClick={uploadFileAndAddevenement}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                          />
                        </svg>
                        Add Event
                      </button>
                    </div>
                  </div>
                ) : (
                  <p className="text-white italic">
                    Bienvenue ! Vous pouvez ajouter un événement à la fois.
                    Veuillez supprimer l'événement existant avant d'en ajouter
                    un nouveau. Merci !
                  </p>
                )}
              </div>

              <div>
                <p className="my-5 text-amber-700 font-bold">
                  Dans ce tableau, vous trouverez la liste des événements. Vous
                  pouvez supprimer un événement en cliquant sur le bouton
                  "Supprimer". Si vous supprimez un événement, il ne sera plus
                  affiché sur la page d'accueil et ne sera plus accessible aux
                  utilisateurs. Soyez prudent lorsque vous supprimez un
                  événement, car cette action ne peut pas être annulée.
                </p>
                <table className="w-full text-white mx-auto my-8 border-collapse">
                  <thead>
                    <tr className="bg-gray-100 border-b-2 border-gray-300 text-left">
                      <th className="py-3 px-4 font-semibold text-gray-700 text-center">
                        Name
                      </th>
                      <th className="py-3 px-4 font-semibold text-gray-700 text-center">
                        Description
                      </th>
                      <th className="py-3 px-4 font-semibold text-gray-700 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {event.map((evenement) => (
                      <tr
                        key={evenement.id}
                        className="border-b-2 border-gray-300"
                      >
                        <td className="py-2 px-4 max-w-xs overflow-hidden whitespace-normal break-words">
                          {evenement.name}
                        </td>
                        <td className="py-2 px-4 max-w-xs overflow-hidden whitespace-normal break-words">
                          <ReadMore>{evenement.description}</ReadMore>
                        </td>
                        <td className="py-2 px-4 flex justify-center space-x-4">
                          <button
                            onClick={() =>
                              deleteEvent(evenement.id, evenement.imageUrl)
                            }
                            className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p className="my-5 text-amber-700 font-bold">
                  Voici comment l'événement sera affiché sur la page d'accueil :
                  une image de hauteur 370px sera affichée à gauche avec le nom
                  de l'événement et sa description à droite. Le nom de
                  l'événement sera en gras et la description sera en dessous. Un
                  bouton pour en savoir plus sur l'événement sera affiché en
                  dessous de la description. Si l'utilisateur clique sur le
                  bouton, il sera redirigé vers une page avec plus de détails
                  sur l'événement.
                </p>
                {event.length > 0 && (
                  <div className="flex flex-col items-center">
                    <div
                      id="text"
                      role="alert"
                      className="relative max-w-xl rounded-lg shadow-2xl bg-gray-300 p-6"
                    >
                      <button
                        type="button"
                        className="absolute -top-1 -right-1 rounded-full border border-gray-500 bg-gray-700 p-1 text-gray-400"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3 w-3"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                        {event.map((evenement) => (
                          <div key={evenement.id} className="">
                            <div className="">
                              <img
                                alt="Affiche festivale"
                                src={
                                  evenement.imageUrl +
                                  "?timestamp=" +
                                  Date.now()
                                }
                                className="h-[300px] w-full rounded-xl object-fill sm:h-auto sm:rounded-none sm:object-contain"
                              />
                            </div>
                            <div className="">
                              <h2 className="text-lg font-medium">
                                {evenement.name}
                              </h2>
                              <p className="mt-4 text-sm text-gray-500">
                                {evenement.description}
                              </p>
                              <div className="mt-6 lg:text-right">
                                <Link
                                  to="/Festivale"
                                  className="inline-block rounded-lg px-5 py-3 text-sm font-medium text-white bg-amber-600 hover:bg-amber-400 hover:text-black"
                                >
                                  <button className=""></button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eventm;
