import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

const Festi20 = () => {
  const imageSources = [];

  // Load all images with a matching filename pattern
  const imageRegex = /^m(\d+)\.(jpg|JPG)$/;
  const imagePaths = import.meta.glob('/src/assets/media1/*.{jpg,jpeg,JPG}');
  console.log("Image paths:", imagePaths);
  Object.keys(imagePaths).forEach((key) => {
    if (imageRegex.test(key)) {
      const image = imagePaths[key]();
      console.log('Loaded image:', image.default);
      imageSources.push(image);
    }
  });
  

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const imageChunks = [];
  for (let i = 0; i < imageSources.length; i += 10) {
    imageChunks.push(imageSources.slice(i, i + 10));
  }

  return (
    <div >
      <Swiper
        modules={[Pagination, Navigation]}
        slidesPerView={1}
        navigation
        pagination={pagination}
      >
        {imageChunks.map((chunk, index) => (
          <SwiperSlide key={index}>
           
          </SwiperSlide>
        ))}
      </Swiper>
      <div>End of component</div>
    </div>
  );
};

export default Festi20;
