import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";



const GalleryImg = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <div>
      <Swiper modules={[Pagination, Navigation]} slidesPerView={1} navigation pagination={{ clickable: true }}>
        <SwiperSlide>
          
        </SwiperSlide>
        <SwiperSlide>
          {/* add remaining images */}
        </SwiperSlide>
        {/* add more slides as necessary */}
      </Swiper>
    </div>
  )
  
};
export default GalleryImg;