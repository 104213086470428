import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Pagination } from "swiper";
import { storage } from "../firebase-config";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { useTranslation } from "react-i18next";
import AnimatedText from "react-animated-text-content"
const Festi21 = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    // Retrieve a list of all files in the "media 4" folder
    const folderRef = ref(storage, "media3/");
    listAll(folderRef)
      .then((res) => {
        const urls = [];
        res.items.forEach((itemRef) => {
          // Get the download URL of each file and add it to the array of URLs
          getDownloadURL(itemRef).then((url) => {
            urls.push(url);
            if (urls.length === res.items.length) {
              // Once we've retrieved all the URLs, update the state
              setImageUrls(urls);
            }
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const imagesPerSlide = 5;
  const totalSlides = Math.ceil(imageUrls.length / imagesPerSlide);

  return (
    <div className="pt-24 bg-gray-900">
       <h1 className="text-center text-4xl text-amber-400 mb-5 font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-amber-600">
                  <AnimatedText
                    type="words" // animate words or chars
                    animation={{
                      x: "200px",
                      y: "-20px",
                      scale: 1.1,
                      ease: "ease-in-out",
                    }}
                    animationType="float"
                    interval={0.06}
                    duration={1}
                    tag="p"
                    className="animated-paragraph"
                    includeWhiteSpaces
                    threshold={0.1}
                    rootMargin="20%"
                  >
                    {t("Festival")}
                  </AnimatedText>
                </span>
                2021
              </h1>
      <div className="grid lg:grid-cols-3 gap-4 grid-cols-1 md:grid-cols-2 px-4">
        {[...Array(totalSlides)].map((_, slideIndex) => (
          <div key={slideIndex} className="">
            <Swiper
              key={slideIndex}
              effect={"fade"}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              modules={[EffectFade, Navigation, Pagination]}
              spaceBetween={30}
              className="mySwiper"
            >
              {imageUrls
                .slice(
                  slideIndex * imagesPerSlide,
                  slideIndex * imagesPerSlide + imagesPerSlide
                )
                .map((url, index) => (
                  <SwiperSlide key={index}>
                    <div class="">
                      <img
                        src={url}
                        alt=""
                        class="flex mb-3 h-[300px] w-full rounded-3xl object-cover"
                       
                      />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        ))}
      </div>
    </div>
  
  );
};

export default Festi21;