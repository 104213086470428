import React from "react";
import mavatr from "../assets/img/business.jpg"
import favatr from "../assets/img/attractive.jpg"
import bimg from "../assets/img/oud.jpg"
import { useTranslation } from 'react-i18next';


const MembreA = () => {
  const { t } = useTranslation();
  const people = [
    {
      name: "Mr Moussa Babahssine",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Nabil Asri",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Mohamed Najih",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Azzedine Mountassir",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Rachid Ouellaj",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "MLE Meriem Lahak",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Reddad Elaj",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abdelhak Kalla",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abderrahim Ouzzine",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Mostafa Amalki",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Dr Karim Bouzoubaa",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Bouchaib Erradi",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abdellah El Miry",
      role: "Membre honoraire",
      imageUrl: mavatr,
     },
    {
      name: "Mr Youssef Louza",
      role: "Membre honoraire",
      imageUrl: mavatr,
      },
    {
      name: "Mr Mohamed Moda",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mr Ahmed Laknoune",
      role: "Membre honoraire",
      imageUrl: mavatr,
    },
    {
      name: "Mlle Nassima Imrane",
      role: "Membre honoraire",
      imageUrl: favatr,
    },
    {
      name: "Mr Bouchaib Zaari",
      role: "Membre honoraire",
      imageUrl: mavatr,
     },
    {
      name: "Mr Mohamed Latrach",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Ahmad Benyahia",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mlle Fatima Zahra Aguedach",
      role: "Membre",
      imageUrl: favatr,
    },
    {
      name: "Mr Yassine Tabyaoui",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mlle Sara Tejera Di Léon",
      role: "Membre",
      imageUrl: favatr,
    },
    {
      name: "Mme Bouchra Boufettal",
      role: "Membre",
      imageUrl: favatr,
    },
    {
      name: "Mlle Malak Boufettal",
      role: "Membre",
      imageUrl: favatr,
    },
    {
      name: "Mr Mohamed Amine Boufettal",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abdelmjid Karam",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mlle Myriam Ait Massoud",
      role: "Membre",
      imageUrl: favatr,
    },
    {
      name: "Mlle Anas Fatima",
      role: "Membre",
      imageUrl: favatr,
    },
    {
      name: "Mme Khadija Otmane Barkhoukh",
      role: "Membre",
      imageUrl: favatr,
    },
    {
      name: "Mr Abdou Berrada",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Mouad Bourouayene",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Mohamed Cherrouk",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Akkor Mohamed",
      role: "Membre",
      
      imageUrl: mavatr,
    },
    {
      name: "Mlle Majda Dakir",
      role: "Membre",
      imageUrl: favatr,
    },
    {
      name: "Mr Abdelilah El Barhmi",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Jawad Marzouk",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abdelilah Miswab",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Nadim Asri",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Prof Zhor Kalkhi",
      role: "Membre",
      imageUrl: favatr,
    },
    {
      name: "Mlle Rachidi Asmaa",
      role: "Membre",
      imageUrl: favatr,
    },
    {
      name: "Mr Bouchaib Radi",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abdou Ramdoune",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Tarik El Ghazi",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Walid Asry",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Aness Fellahi",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Mounir Beddah",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Kamal Benmeriem",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Aymane Douhal",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abdelilah El Barhmi",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr El Mehdi El Ouaqoauaqi",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Nadia Essafi",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Khalid Herradi",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abdelghani Khadir",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mlle Salma Khourcha",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Jamal Addi",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Madani Otmane",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Mohamad Iraqui Houssaini",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Mostafa Mjared",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Moulay M'hamed Dammou",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Hamouda Chnafa Radi",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abdeljabbar Rhars",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Sidki Mohamed",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abdeltif Souheil",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Walid Boukhlal",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Sanad Zlitni",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Houssine Zouhari",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Abdeltif lagrini",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Jamal Raoul",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr  Machhour Makhoul Majd",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Issam Aoussif",
      role: "Membre",
      imageUrl: mavatr,
    },
    {
      name: "Mr Aimad Aoussif",
      role: "Membre",
      imageUrl: mavatr,
    },
  ];
  return (
    <div>
      <div className="">
        <div className=" max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="fixed inset-0 z-0">
                  <img
                    className="h-screen w-screen object-cover"
                    src={bimg}
                    alt=""
                  />
                  <div className="absolute inset-0 bg-[#8d714a]/90 mix-blend-multiply" />
                </div>
          <div className="relative space-y-8 sm:space-y-12">
            <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
              <h2 className="text-3xl text-white font-extrabold tracking-tight sm:text-4xl">
                {t('Membres de l’association Quatrième Art')}
              </h2>
            </div>
            <ul className="mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6">
              {people.map((person) => (
                <li key={person.name}>
                  <div className="space-y-4">
                    <img
                      className="mx-auto h-10 w-10 object-cover rounded-full lg:w-14 lg:h-14  blur-[1.5px]"
                      src={person.imageUrl}
                      alt=""
                    />
                    <div className="space-y-2">
                      <div className="text-xs font-medium lg:text-sm">
                        <h3 className="text-white font-bold italic">{t(person.name)}</h3>
                        <p className="text-indigo-200">{t('Membre')}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembreA;
