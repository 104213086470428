import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Trioim from './components/Trioim';
const Trio = () => {
  const { t } = useTranslation();
  const animations = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
  };
  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.4 }} className="w-full h-full object-cover bg-[url(./assets/trio1/trbg.jpeg)]"
       
    >


      <div className="px-4 lg:px-0 py-10">
        <Link to="Gal" smooth={true} offset={-25} duration={800}>
          <button className="field mx-auto mt-20">
            <div class="scroll"></div>
          </button>
        </Link>
        <h1 className="text-center">{t("défiler vers le bas")}</h1>
        <div className="border-2 z-20 border-[#8d714a] mx-auto py-10 w-full md:w-[700px] bottom-10 rounded-2xl shadow-2xl bg-zinc-800 relative top-28">
          <div className="text-center">
            <img
              src={require("./assets/img/logo_bd44dd10fa733d2ae526e648261f2ca8_1x.png")}
              className="top-[-36px] md:top-[-45px] h-20 lg:h-24 left-28 object-cover sm:left-[280px] absolute"
              alt="logo"
            />
            <h1 className="text-3xl lg:text-6xl  text-[#8d714a] font-bold drop-shadow-xl">
              {t("TRIO")}
            </h1>
          </div>
        </div>
        <div>
          <Trioim />
        </div>
        </div>
    </motion.div>
  );
};

export default Trio;
