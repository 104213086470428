import React, { useRef } from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import loading from "../assets/img/1491.gif";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaTelegramPlane } from "react-icons/fa";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Regi1 = () => {
  const form = useRef();
  const { t } = useTranslation();
  const sendEmail = (e) => {
    e.preventDefault();
    toast.warn("Charger, Loading", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: ({ theme, type }) => <img src={loading} alt="load" />,
    });
    emailjs
      .sendForm("Gmail", "template_7b0ah6s", form.current, "EOZsNqlBQe1GdnuI5")
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message Sent");
          toast.success("Message Envoyer, Message Sent", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          console.log(error.text);
          toast.warn("Message Echouer", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
  };
  return (
    <div>
      <div className="mx-auto">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className=" bg-zinc-800 px-4 lg:px-4 pt-10 lg:mt-10 h-[350px] w-screen  lg:h-screen sm:px-6 sm:text-center lg:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
            <div>
              <h1 class="inline-flex items-center text-white mt-14 lg:mt-0 bg-gray-900 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                <span class="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-amber-600 rounded-full">
                  {t("ENVIE DE NOUS REJOINDRE?")}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="ml-2 w-5 h-5 text-gray-500"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </h1>
              <h1 class="mt-4 text-4xl my-8 space-y-5 tracking-tight font-extrabold text-zinc-100 sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                <span class="md:block drop-shadow-2xl">
                  {t("Devenir Membre")}
                </span>
              </h1>
              <p class="mt-3 text-base text-gray-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                {t(
                  "Vous êtes intéressé par l’art ou vous aimez l’esprit d’entraide et de solidarité."
                )}
              </p>
            </div>
          </div>

          <div class="md:my-4 lg:mt-24 lg:col-span-6">
            <div class="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
              <div class="bg-white px-6 py-8 shadow-md drop-shadow-lg rounded-lg text-black w-full">
                <p className="mb-2 italic decoration-solid text-amber-500">
                  {t("Rejoignez-nous aussi sur :")}
                </p>
                <div className=" md:ml-4 gap-4 md:flex-shrink-0 md:flex md:items-center">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="http://www.facebook.com/asso4art"
                  >
                    <button
                      type="button"
                      className="bg-zinc-700 p-1 rounded-full text-blue-600 hover:text-gray-500 focus:outline-none"
                    >
                      <span className="sr-only">Facebook</span>
                      <FaFacebook className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="http://www.instagram.com/asso4art/?igshid=YmMyMTA2M2Y%3D"
                  >
                    <button
                      type="button"
                      className="bg-zinc-700 p-1 rounded-full text-pink-800 hover:text-gray-500 focus:outline-none"
                    >
                      <span className="sr-only">instagram</span>
                      <FaInstagram className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="http://www.youtube.com/channel/UC5yg8Ft377tcAkmj6Tfw2DA"
                  >
                    <button
                      type="button"
                      className="bg-zinc-700 p-1 rounded-full text-red-600 hover:text-gray-500 focus:outline-none"
                    >
                      <span className="sr-only">Youtube</span>
                      <FaYoutube className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </a>
                </div>
                <div className="w-full mt-8 border-t border-gray-300 mb-4"></div>
                <h3 class="font-semibold text-zinc-700 mb-4">
                  {t("REGISTRATION")}
                </h3>

                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="grid grid-cols-1 gap-y-6"
                >
                  <div>
                    <label htmlFor="full-name" className="sr-only">
                      {t("Nom Complet")}
                    </label>
                    <input
                      type="text"
                      name="user_name"
                      id="full-name"
                      autoComplete="name"
                      className="block w-full text-black shadow-lg py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      placeholder="Full name"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="sr-only">
                      {t("Email")}
                    </label>
                    <input
                      id="email"
                      name="user_email"
                      type="email"
                      autoComplete="email"
                      className="block w-full text-black shadow-lg py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="tel" className="sr-only">
                      {t("Numero de Telephone")}
                    </label>
                    <input
                      id="tel"
                      name="user_phone"
                      type="tel"
                      autoComplete="tel"
                      className="block w-full text-black shadow-lg py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      placeholder="Telephone"
                      required
                    />
                    <label htmlFor="tel" className="sr-only">
                      {t("Instrument")}
                    </label>
                    <input
                      id="tel"
                      name="user_instru"
                      type="instru"
                      autoComplete="instrument"
                      className="block w-full mt-5 text-black shadow-lg py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      placeholder="Instrument"
                      required
                    />

                    <div class="flex items-center mt-3">
                      <input
                        required
                        id="link-checkbox"
                        type="checkbox"
                        value=""
                        class="w-6 h-6 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="link-checkbox"
                        class="ml-2 text-sm font-medium text-red-700"
                      >
                        *
                        {t(
                          "Droit d’inscription 100dh par an à verser au compte de l’association quatrième art : Attijari 007780000413200030200335."
                        )}
                      </label>
                    </div>

                    <p className="mb-2 mt-5 italic decoration-solid text-amber-500">
                      *{t("Envoyez votre photo a notre email:")}
                      <a
                        href="mailto:asso4art@gmail.com"
                        className=" text-amber-500 ml-3"
                      >
                        asso4art@gmail.com
                      </a>
                    </p>
                  </div>
                  <div>
                    <AnimationOnScroll
                      animateIn="animate__bounceIn"
                      animateOnce="true"
                    >
                      <button
                        type="submit"
                        className="flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-amber-600 hover:bg-amber-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <p className="">{t("Envoyer")}</p>
                        <FaTelegramPlane className="h-6 ml-2" />
                      </button>
                    </AnimationOnScroll>
                  </div>
                </form>
              </div>
              <div class="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10">
                <p class="text-xs leading-5 text-gray-500">
                  {t(
                    "on vas vous contacter apres quand recoit la registration merci."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regi1;
