import React from 'react'
import { Fes1 } from './components/Fes1'
const Festivale = () => {
  return (
    <div>
        <Fes1 />
    </div>
  )
}

export default Festivale