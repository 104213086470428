import React, { useState, useEffect } from "react";
import { db } from "./firebase-config";

import { collection, getDocs, query } from "firebase/firestore";
import Events from "./components/Events";
import MotPresident from "./components/MotPresident";
import Hero from "./components/Hero";
import Bureau from "./components/Bureau";
import { motion } from "framer-motion";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
const Home = () => {
  const [event, setEvent] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDocs(query(collection(db, "Events")));
      setEvent(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
  }, []);
  function change() {
    document.getElementById("text").style.display = "none";
  }
  const animations = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
  };
  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.4 }}
      className="bgbody h-[100%] w-[100%] relative overflow-hidden"
    >
      <main>
        {/* Hero card */}
        <Hero />
        {/* mot president */}
        <MotPresident />
        {/* bureau */}
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          duration={1}
          animateOnce="true"
        >
          <Bureau />
        </AnimationOnScroll>
        {/* events */}
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          duration={1}
          animateOnce="true"
        >
          <div className=" w-full">
            <Events />
          </div>
        </AnimationOnScroll>
        {/* More main page content here... */}
        <div class=" fixed z-10 inset-x-0 bottom-0 p-4">
          {event.length > 0 && (
            <div className="">
              <div
                id="text"
                role="alert"
                className="relative max-w-xl rounded-lg shadow-2xl bg-gray-300 p-6"
              >
                <button
                  onClick={() => change()}
                  type="button"
                  className="absolute -top-1 -right-1 rounded-full border border-gray-500 bg-gray-700 p-1 text-gray-400"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <div className="">
                     {event.map((evenement) => (
                       <div key={evenement.id} className="">
                         <div className="">
                           <img
                             alt="Affiche festivale"
                             src={evenement.imageUrl + "?timestamp=" + Date.now()}
                             className="h-[300px] w-full rounded-xl object-fill sm:h-auto sm:rounded-none sm:object-contain"
                           />
                         </div>
                         <div className="">
                           <h2 className="text-lg font-medium">{evenement.name}</h2>
                           <p className="mt-4 text-sm text-gray-500">
                             {evenement.description}
                           </p>
                           <div className="mt-6 lg:text-right">
                             <Link
                               to="/Festivale"
                               className="inline-block rounded-lg px-5 py-3 text-sm font-medium text-white bg-amber-600 hover:bg-amber-400 hover:text-black"
                             >
                               <button className=""></button>
                             </Link>
                           </div>
                         </div>
                       </div>
                     ))}
                   </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </motion.div>
  );
};

export default Home;
