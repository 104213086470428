import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import evimg1 from "../assets/img/image2.jpeg";
import evimg2 from "../assets/img/image3.jpeg";
import evimg3 from "../assets/img/IMG_E0589.jpg";
import evimg4 from "../assets/img/Affiche-A3-Hommage-said-chraibi-22-Mars-2017-BLEUE-b-724x1024.jpg";
import evimg5 from "../assets/img/WhatsApp-Image-2019-02-28-at-09.45.00.jpeg";
import feimg1 from "../assets/img/festival-Oud.jpeg";
import feimg2 from "../assets/img/54430487_1150304691818273_5121426519666720768_o.jpg";
import feimg3 from "../assets/img/event-724x1024.jpg";
import { useTranslation } from 'react-i18next';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
// import required modules
import { EffectCards } from "swiper";
const Events = () => {
  const { t } = useTranslation();
  return (
    <div className="grid md:grid-cols-2 mb-16 grid-cols-1 relative border-t-2 bg-white">
      <div className=" md:border-r-2 ">
        <div className="mx-auto align-middle justify-center rounded-xl w-[320px] h-[620px]">
          <h2 className="text-4xl drop-shadow-xl font-bold text-center pb-8">
            {t('Les Evenements')}
          </h2>
          <React.Fragment>
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  src={evimg1}
                  alt=""
                  className="rounded-xl border-2 border-white"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={evimg2}
                  alt=""
                  className="rounded-xl border-2 border-white"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={evimg3}
                  alt=""
                  className="rounded-xl border-2 border-white"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={evimg4}
                  alt=""
                  className="rounded-xl border-2 border-white"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={evimg5}
                  alt=""
                  className="rounded-xl border-2 border-white"
                />
              </SwiperSlide>
            </Swiper>
          </React.Fragment>
        </div>
      </div>

      <div className="mx-auto align-middle justify-center  rounded-xl w-[320px] h-[620px]">
        <h2 className="text-4xl drop-shadow-xl font-bold text-center pb-8">
          {t('Les Festivals')}
        </h2>
        <React.Fragment>
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src={feimg1}
                alt=""
                className="rounded-xl border-2 h-[453px] border-white"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={feimg2}
                alt=""
                className="rounded-xl border-2 border-white"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={feimg3}
                alt=""
                className="rounded-xl border-2 border-white"
              />
            </SwiperSlide>
          </Swiper>
        </React.Fragment>
      </div>
    </div>
  );
};

export default Events;
