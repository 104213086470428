import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase-config";

import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Bureau = () => {
  const [people, setPeople] = useState([]);

  useEffect(() => {
    const peopleRef = collection(db, "people");
    const unsubscribe = onSnapshot(query(peopleRef, orderBy("order")), (snapshot) => {
      const newPeople = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPeople(newPeople);
    });
    return unsubscribe;
  }, []);

  const { t } = useTranslation();

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text max-w-[350px] line-clamp-2">
        {isReadMore ? text.slice(0, 20) : text}
        <button
          onClick={toggleReadMore}
          className="read-or-hide text-zinc-500 hover:text-zinc-300"
        >
          {isReadMore ? "...Plus" : "...<=="}
        </button>
      </p>
    );
  };
  return (
    <div className="mb-16 relative">
      <div className="mx-auto relative bg-white py-6 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-10">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {t("BUREAU")}
            </h2>
            <p className="text-xl text-gray-500">
              {t("Les members du bureau actuel")}
            </p>
          </div>
          <ul className="space-y-12  pb-10 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
            {people.map((person) => (
              <li key={person.id}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2">
                    <AnimationOnScroll
                      animateIn="animate__fadeIn"
                      duration={2}
                      animateOnce="true"
                    >
                      <img
                        className="object-cover shadow-lg rounded-lg"
                        src={person.imageUrl + "?timestamp=" + Date.now()}
                        alt=""
                      />
                    </AnimationOnScroll>
                  </div>
                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3 className="font-bold text-xl uppercase text-[#8d714a]">
                        {t(person.name)}
                      </h3>
                      <p className="text-indigo-600 text-lg font-semibold">
                        {t(person.role)}
                      </p>
                      <p className="text-gray-600 text-sm font-semibold max-w-xs overflow-hidden whitespace-normal break-words">
                        <ReadMore>{t(person.description)}</ReadMore>
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 pt-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("la list des membres d'Association Quatrième Art")}
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>{t("vous pouvez voir toute la list des membres")}</p>
            </div>
            <div className="mt-3 text-sm">
              <Link
                to="/Members"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {t("voir plus")} <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bureau;
