import React from 'react'
import Regi1 from './components/Regi1'
export const Registration = () => {
  return (
    
    <div>
        <div className='w-full h-full object-fit bg-[url(./assets/img/regibg.jpg)]'>
        <Regi1 />
        </div>
    </div>
  )
}
