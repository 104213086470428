import React, {useRef} from "react";
import emailjs from "@emailjs/browser";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { FaFacebook, FaInstagram, FaYoutube, FaTelegramPlane, FaMapPin} from "react-icons/fa"
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loading from "../assets/img/1491.gif";

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    toast.warn("Charger, Loading", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: ({theme, type}) =>  <img src={loading} alt="load"/>
    });
    emailjs.sendForm('Gmail', 'template_5cmj0yc', form.current, 'oejZR2HTq4XZfpo7T')
    .then((result) => {
      console.log(result.text);
      console.log("Message Sent");
      toast.success("Message Envoyer, Message Sent", {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    }, (error) => {
      console.log(error.text);
      toast.warn("Message Echouer", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    });
  };
  return (
    <div className="border-y-2 bg-transparent w-full">
    <div name="contact" className="relative bg-transparent">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12 border-r-2">
          <div className="max-w-lg mx-auto">
          <AnimationOnScroll animateIn="animate__bounceIn" animateOnce="true">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {t('Contactez-Nous')}
            </h2>
            </AnimationOnScroll>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd className="flex">
                  <FaMapPin className="flex-shrink-0 h-6 w-6 text-blue-500"/>
                  <p className="ml-3">{t('Complexe socio-éducatif Derb Ghallef')}<br></br>{t('Casablanca')}<br></br>{t('Maroc')}</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <a href="tel:+212661191269" className="ml-3">+212 (0) 6 61 19 12 69</a>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                  <a href="mailto:asso4art@gmail.com" className="ml-3">asso4art@gmail.com </a>
                </dd>
              </div>
            </dl>
            <p className="mt-6  text-base text-gray-500">
              {t('Rester En ligne')}
            </p>
            <div className="flex gap-2 pt-3">
            <a rel="noreferrer" target="_blank"  href="http://www.facebook.com/asso4art">
             <button type="button" className="rounded-2xl bg-slate-300 py-2 px-2 font-medium text-gray-700 underline inline">
                <FaFacebook/>
              </button>
              </a>
              <a rel="noreferrer" target="_blank"  href="http://www.instagram.com/asso4art/?igshid=YmMyMTA2M2Y%3D">
              <button  type="button" className="rounded-2xl bg-slate-300 py-2 px-2 font-medium text-gray-700 underline inline">
                <FaInstagram/>
              </button>
              </a>
              <a rel="noreferrer" target="_blank" href="http://www.youtube.com/channel/UC5yg8Ft377tcAkmj6Tfw2DA">
              <button href="http://www.youtube.com/channel/UC5yg8Ft377tcAkmj6Tfw2DA" type="button" className="rounded-2xl bg-slate-300 py-2 px-2 font-medium text-gray-700 underline inline">
                <FaYoutube/>
              </button>
              </a>
              </div>
          </div>
        </div>
        <div className=" py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form ref={form} onSubmit={sendEmail} className="grid grid-cols-1 gap-y-6">
              <div>
                <label htmlFor="full-name" className="sr-only">
                  Nom Complet
                </label>
                <input
                  type="text"
                  name="user_name"
                  id="full-name"
                  autoComplete="name"
                  className="block w-full shadow-lg py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Full name"
                 required/>
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="user_email" 
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-lg py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Email"
                 required/>
              </div>
              <div>
              <label htmlFor="tel" className="sr-only">
                  Numero de Telephone
                </label>
                <input
                  id="tel"
                  name="user_phone" 
                  type="tel"
                  autoComplete="tel"
                  className="block w-full shadow-lg py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="Telephone"
                 required/>
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                  placeholder="Message"
                  defaultValue={""}
                 required/>
              </div>
              <div>
              <AnimationOnScroll animateIn="animate__bounceIn" animateOnce="true">
              <button
                  type="submit"
                  className="flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-amber-600 hover:bg-amber-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <p className="">{t('Envoyer')}</p><FaTelegramPlane className="h-6 ml-2"/>
                </button>
                </AnimationOnScroll>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Contact;