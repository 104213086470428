import React from "react";
import himg from "../assets/img/ADL_2580.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AnimatedText from "react-animated-text-content";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div>
      {/* Hero card */}
      <div className="relative  mt-28">
        <div className="absolute inset-x-0 bottom-0 h-1/2 " />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <img className="h-full w-full object-cover" src={himg} alt="" />
              <div className="absolute inset-0 bg-[#8d714a] mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">
                  <AnimatedText
                    type="words" // animate words or chars
                    animation={{
                      x: "200px",
                      y: "-20px",
                      scale: 1.1,
                      ease: "ease-in-out",
                    }}
                    animationType="float"
                    interval={0.06}
                    duration={1}
                    tag="p"
                    className="animated-paragraph"
                    includeWhiteSpaces
                    threshold={0.1}
                    rootMargin="20%"
                  >
                    {t("Association Quatrième Art")}
                  </AnimatedText>
                </span>
              </h1>
              <p className="text-center block text-indigo-200">
                <AnimatedText
                  type="words" // animate words or chars
                  animation={{
                    x: "200px",
                    y: "-20px",
                    scale: 1.1,
                    ease: "ease-in-out",
                  }}
                  animationType="float"
                  interval={0.06}
                  duration={2}
                  tag="p"
                  className="animated-paragraph"
                  includeWhiteSpaces
                  threshold={0.1}
                  rootMargin="20%"
                >
                  {t("une association à but non lucratif")}
                </AnimatedText>
              </p>
              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                {t(
                  "Depuis sa création, l’Association Quatrième Art participe à la promotion de l’art musical à travers le don de cours de musique, de solfège et d’instruments de façon hebdomadaire."
                )}
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <Link
                    type="button"
                    smooth={true}
                    offset={-25}
                    duration={800}
                    to="/Registration"
                    className="flex"
                  >
                    <button className="flex w-full items-center justify-center lg:ml-[510px] px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-gray-800 bg-white hover:bg-opacity-10 hover:text-white sm:px-8">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 inline mr-1 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                        />
                      </svg>

                      {t("Devenir Membre")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
