import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import img1 from "../assets/img/logo_bd44dd10fa733d2ae526e648261f2ca8_1x.png";

const Me1 = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white dark:bg-gray-800">
      <div className="container px-6 py-16 mx-auto">
        <div className="flex flex-col items-center sm:flex-row sm:w-2/3 lg:w-2/5">
          <div className="w-full sm:w-1/3 lg:w-3/5 mb-4 sm:mb-0">
            <img src={img1} alt="logo" className="w-full" />
          </div>
          <div className="flex flex-col items-center sm:items-start sm:ml-10">
            <h1 className="text-4xl font-black leading-none text-gray-800 uppercase font-bebas-neue sm:text-8xl dark:text-white">
              {t("Association Quatrième")}
              <span className="text-5xl sm:text-7xl"> {t("Art")}</span>
            </h1>
            <p className="mt-4 text-sm text-gray-700 sm:text-base dark:text-white">
              {t("Ici vous trouvez toutes les photos des festivals de chaque année")}
              <br /> {t("Veuillez sélectionner l'année ci-dessous.")}
            </p>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 mt-8">
              <Link
                to="/Festival2019"
                smooth={true}
                offset={-25}
                duration={800}
                className="flex"
              >
                <button className="px-4 py-2 text-white uppercase bg-pink-500 border-2 border-transparent rounded-lg text-md hover:bg-pink-400">
                  {t("Festival")} 2019
                </button>
              </Link>

              <Link
                to="/Festival2020"
                smooth={true}
                offset={-25}
                duration={800}
                className="flex"
              >
                <button className="px-4 py-2 text-white uppercase bg-pink-500 border-2 border-transparent rounded-lg text-md hover:bg-pink-400">
                  {t("Festival")} 2020
                </button>
              </Link>

              <Link
                to="/Festival2021"
                smooth={true}
                offset={-25}
                duration={800}
                className="flex"
              >
                <button className="px-4 py-2 text-white uppercase bg-pink-500 border-2 border-transparent rounded-lg text-md hover:bg-pink-400">
                  {t("Festival")} 2021
                </button>
              </Link>

              <Link
                to="/Festival2022"
                smooth={true}
                offset={-25}
                duration={800}
                className="flex"
              >
                <button className="px-4 py-2 text-white uppercase bg-pink-500 border-2 border-transparent rounded-lg text-md hover:bg-pink-400">
                  {t("Festival")} 2022
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Me1;

