import Footer from "./components/Footer";
import React from "react";
import Home from "./Home";
import Navbar from "./components/Navbar";
import Contact from "./components/Contact";
import Newsletter from "./components/Newsletter";
import Members from "./Members";
import Media from "./Media";
import Orchestres from "./Orchestres";
import Trio from "./Trio";
import Festivale from "./Festivale";
import { Registration } from "./Registration";
import Eventm from "./components/Evenm";
// eslint-disable-next-line
import {
  
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import { AnimatePresence } from "framer-motion";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Imgs from "./components/imgs";
import Atte1 from "./components/Atte1";
import Imgs2 from "./components/Imgs2";
import Galleryimg from "./components/GalleryImg";
import Festi20 from "./components/Festi20";
import Festi21 from "./components/Festi21";
import Festi22 from "./components/Festi22";
import Signin from "./Signin";
import Account from "./components/Account";
import { AuthContextProvider } from "./Context/AuthContext";
import ProtectedRoute from './components/ProtectedRoute';
import Memberad from "./components/Memberad";
function App() {
  const location = useLocation();
  return (
    <div className="app">
      <Navbar />
      <div className="content">
        <ScrollToTop />
        <AnimatePresence>
          <AuthContextProvider>
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Home />} />
              <Route path="/Members" element={<Members />} />
              <Route path="/Media" element={<Media />} />
              <Route path="/Orchestres" element={<Orchestres />} />
              <Route path="/Trio" element={<Trio />} />
              <Route path="/Festivale" element={<Festivale />} />
              <Route path="/Registration" element={<Registration />} />
              <Route path="/attestation" element={<Imgs />} />
              <Route path="/Participation" element={<Imgs2 />} />
              <Route path="/Festival2019" element={<Galleryimg />} />
              <Route path="/Festival2020" element={<Festi20 />} />
              <Route path="/Festival2021" element={<Festi21 />} />
              <Route path="/Festival2022" element={<Festi22 />} />
              <Route path="/Signin" element={<Signin />} />
              <Route
                path="/account"
                element={
                  <ProtectedRoute>
                    <Account />
                  </ProtectedRoute>
                }
              />
               <Route
                path="/Events"
                element={
                  <ProtectedRoute>
                    <Eventm />
                  </ProtectedRoute>
                }
              />
               <Route
                path="/Adminmembers"
                element={
                  <ProtectedRoute>
                    <Memberad />
                  </ProtectedRoute>
                }
              />
              <Route path="/static/media/:params" element={<Atte1 />} exact />
            </Routes>
          </AuthContextProvider>
        </AnimatePresence>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          duration={1}
          animateOnce="true"
        >
          <Contact />
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          duration={1}
          animateOnce="true"
        >
          <Newsletter />
        </AnimationOnScroll>
      </div>
      <Footer />
    </div>
  );
}

export default App;
