import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import './i18next';
import {BrowserRouter as Router} from 'react-router-dom';


import "firebase/compat/storage"

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Router>
    <App />
  </Router>
);



