import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Imgs2 = () => {
  const { t } = useTranslation();

  const Participation = [
    {
      name: "MR KAMAL BENDOUROU",
      url: require("../assets/parti/parti (1).jpeg"),
    },
    {
      name: "MR OMAR ABOUKAD",
      url: require("../assets/parti/parti (2).jpeg"),
    },
    {
      name: "MR MOHAMED AKOUR",
      url: require("../assets/parti/parti (4).jpeg"),
    },
    {
      name: "MR BOUCHAIB ERRADI",
      url: require("../assets/parti/parti (5).jpeg"),
    },
    {
      name: "MR MOSTAFA AMLAKI",
      url: require("../assets/parti/parti (6).jpeg"),
    },
    {
      name: "MR ABDELWAHAB DOUKKALI",
      url: require("../assets/parti/parti (7).jpeg"),
    },
    {
      name: "MR MOHAMED ELMODA",
      url: require("../assets/parti/parti (8).jpeg"),
    },
    {
      name: "MR YOUSSEF LOUZA",
      url: require("../assets/parti/parti (9).jpeg"),
    },
    {
      name: "MR IHSSANE ZOUHIR",
      url: require("../assets/parti/parti (10).jpeg"),
    },
    {
      name: "MR MOUAD BOURWAYN",
      url: require("../assets/parti/parti (11).jpeg"),
    },
    {
      name: "MR LAHCEN KOUKOUS",
      url: require("../assets/parti/parti (12).jpeg"),
    },
    {
      name: "MR MOHAMED CHOUROUK",
      url: require("../assets/parti/parti (13).jpeg"),
    },
    {
      name: "MR WALID ELASRI",
      url: require("../assets/parti/parti (14).jpeg"),
    },
    {
      name: "MR NADIM ELASRI",
      url: require("../assets/parti/parti (15).jpeg"),
    },
    {
      name: "MR ABDELAH AKCHOUR",
      url: require("../assets/parti/parti (16).jpeg"),
    },
    {
      name: "MR ABDELILAH ELBERHMI",
      url: require("../assets/parti/parti (17).jpeg"),
    },
    {
      name: "MR JAWAD MARZOUK",
      url: require("../assets/parti/parti (18).jpeg"),
    },
    {
      name: "MME KHADIJA OTHMANE BARKHOUKH",
      url: require("../assets/parti/parti (19).jpeg"),
    },
    {
      name: "MR MOHAMED ERROUSAFI",
      url: require("../assets/parti/parti (20).jpeg"),
    },
    {
      name: "MR HAMID ELGHABOUKI",
      url: require("../assets/parti/parti (21).jpeg"),
    },
    {
      name: "MR OLIVIER BOUSSAN",
      url: require("../assets/parti/parti (22).jpeg"),
    },
    {
      name: "MR REMI ALAN",
      url: require("../assets/parti/parti (23).jpeg"),
    },
    {
      name: "MR MERIEM MASSOUD",
      url: require("../assets/parti/parti (24).jpeg"),
    },
    {
      name: "MR HOUDA MAKNI",
      url: require("../assets/parti/parti (25).jpeg"),
    },
    {
      name: "MR REMI ALMBIMBA DYBAT",
      url: require("../assets/parti/parti (26).jpeg"),
    },
    {
      name: "MR KARAM ABDELMAJID",
      url: require("../assets/parti/parti (27).jpeg"),
    },
    {
      name: "MR MAJDA DAKIR",
      url: require("../assets/parti/parti (28).jpeg"),
    },
    {
      name: "MR SAMIR OUAD",
      url: require("../assets/parti/parti (29).jpeg"),
    },
    {
      name: "MME FATIMA ANAS",
      url: require("../assets/parti/parti (30).jpeg"),
    },
    {
      name: "MME ZHOUR ABOU LIZA",
      url: require("../assets/parti/parti (31).jpeg"),
    },
    {
      name: "MR ABDELILAH MISSOUAB",
      url: require("../assets/parti/parti (32).jpeg"),
    },
    {
      name: "MR KHALID AMJAHDI",
      url: require("../assets/parti/parti (33).jpeg"),
    },
    {
      name: "MR ABDELAH WALID",
      url: require("../assets/parti/parti (34).jpeg"),
    },
    {
      name: "MME ASMAE ERRACHIDI",
      url: require("../assets/parti/parti (35).jpeg"),
    },
    {
      name: "MR TOUFIK MARJANA",
      url: require("../assets/parti/parti (36).jpeg"),
    },
    {
      name: "MR KHALID BELHAIBA",
      url: require("../assets/parti/parti (37).jpeg"),
    },
    {
      name: "MR KHALID HADJAMI",
      url: require("../assets/parti/parti (38).jpeg"),
    },
    {
      name: "MR ABDELAH RAMDOUNE",
      url: require("../assets/parti/parti (39).jpeg"),
    },
    {
      name: "MR MOHAMED HRIZ",
      url: require("../assets/parti/parti (40).jpeg"),
    },
    {
        name: "MR AHMED MARJANI",
        url: require("../assets/parti/parti (41).jpeg"),
      },
    {
      name: "DOCTEUR ABDAH BERRADA",
      url: require("../assets/parti/parti (42).jpeg"),
    },
    {
      name: "MR ABDELHAL BENDAHMANE",
      url: require("../assets/parti/parti (43).jpeg"),
    },
    {
      name: "MR MOSTAFA BENMANSOUR",
      url: require("../assets/parti/parti (44).jpeg"),
    },
    {
      name: "MME RABHA ZAHID",
      url: require("../assets/parti/parti (45).jpeg"),
    },
  ];
  return (
    <div>
      <>
        <div class="bg-slate-800 text-white md:py-20 pt-20">
          <div className="flex items-center justify-center">
            <div>
              <img
                src={require("../assets/img/fest1.jpeg")}
                alt="."
                className="rounded-3xl object-cover md:h-[400px] "
              />
            </div>
          </div>
          <h1 className="flex flex-col  items-center text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl mt-10">
            {t("Attestation de participation")}
          </h1>
          <p className="text-center mb-10 mt-3 block text-indigo-200">
            {t(
              "Merci de rechercher votre nom et de télécharger votre attestation."
            )}
          </p>
          <div className="flex flex-col justify-center items-center gap-20">
            <ul className="flex flex-wrap gap-2 items-center justify-center px-4 w-screen">
              {Participation.map((person, idx) => (
                <li key={idx} className="xl:w-[22%] md:w-[30%] w-full">
                  <Link
                    className="uppercase py-2 px-3 flex justify-center items-center my-2 bg-orange-500 rounded-lg shadow-lg hover:ring-1 hover:bg-transparent hover:ring-orange-500 hover:text-orange-500 text-white"
                    to={person.url}
                  >
                    {t(person.name)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    </div>
  );
};

export default Imgs2;
