import "animate.css/animate.min.css";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
export const Fes1 = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div class="bg-slate-800 text-white md:py-20 pb-2 pt-20">
        <div className="flex items-center justify-center">
          <div>
          <img
            src={require("../assets/img/fest1.jpeg")}
            alt="."
            className="rounded-3xl object-cover md:h-[400px] "
          />
          </div>
        </div>
        <div className="mrelative md:-mt-20 -mt-5 top-14 mb-7 md:gap-4 md:flex items-center justify-center">
          <div className="">
            <Link
              to="/attestation"
              className="flex w-full items-center justify-center mt-7 mb-4 px-3 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-gray-800 bg-yellow-300 hover:bg-opacity-10 hover:text-white sm:px-8"
              >{t("Attestations concours de luth")}
            </Link>
            </div>
          <div className="">
            <Link
              to="/Participation"
              className="flex w-full items-center justify-center mb-10 px-3 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-gray-800 bg-yellow-300 hover:bg-opacity-10 hover:text-white sm:px-8"
            >{t("Attestation de participation")}
            </Link>
        </div>
        </div>
      </div>
    </div>
  );
};
export default Fes1;
