import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAareISz1zZI8U_hNRccAtDdt2qM_S6Djs",
    authDomain: "alfan4-d1b7c.firebaseapp.com",
    projectId: "alfan4-d1b7c",
    storageBucket: "alfan4-d1b7c.appspot.com",
    messagingSenderId: "993380515996",
    appId: "1:993380515996:web:497ac834bc1dae0cda6d59",
    measurementId: "G-3P1V61F6XY"
  };
  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);
  export const storage = getStorage(app);
  export const db = getFirestore(app);
  export default app