import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";
import { EffectFade, Navigation, Pagination } from "swiper";
import AnimatedText from "react-animated-text-content";
const video = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  return (
    <div>
      <div className="w-full pt-[50px] text-center flex flex-col drop-shadow-lg h-[150px]">
        <div className=" text-amber-700 text-center">
          <h1 className='class=" text-4xl font-extrabold tracking-tight sm:text-4xl md:text-5xl"'>
            <span className="block text-yellow-700">
              <AnimatedText
                type="words" // animate words or chars
                animation={{
                  x: "200px",
                  y: "-20px",
                  scale: 1.1,
                  ease: "ease-in-out",
                }}
                animationType="float"
                interval={0.06}
                duration={1}
                tag="p"
                className="animated-paragraph"
                includeWhiteSpaces
                threshold={0.1}
                rootMargin="20%"
              >
                {t("L’OCHESTRE 4ÈME ART EN PRESTATION")}
              </AnimatedText>
            </span>
          </h1>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-1 p-4">
          <iframe
            className="flex border-2 mb-3 h-[267px] w-full rounded-3xl md:h-[472px]"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/qbid_PMIqvc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>{" "}
        </div>
      </div>

      <div className="grid grid-cols-1 p-4  md:grid-cols-3 md:gap-7 ">
        <div className="flex mb-3 h-[267px] w-full rounded-3xl ">
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/g6YnhAMtBJM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/z77ua2UsuaU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/tHwLIhN99qY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/6747TTXBQKE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/vFr80ozVrVg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Wp2I7hp0flo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/U0iVq5A7Ao4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/P4FJ7xoeZPE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/FIkT3IraeHQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="flex mb-3 h-[267px] w-full rounded-3xl ">
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/39MJd8ARkqk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/vdXDns7qFJM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/GqG1Up2hMXI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/SNfcDnkuFtk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/bYmwsQgk2lY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/kOAbpRIc2dk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/fyBy9fRZk-g"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/0HKpo91vl9E"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/1RxMYrDTl18"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="flex mb-3 h-[267px] w-full rounded-3xl ">
          <Swiper
            className="flex mb-3 h-[267px] w-full rounded-3xl mySwiper"
            spaceBetween={30}
            effect={"fade"}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination]}
          >
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/kImYW6hKwtw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/qH6IVV62rSM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/gkJj-1mrB8Q"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/jQgTAMw-1ng"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/zx3xFBk5xAU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/e9sIYs69yL8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/zvXMYVOzsRk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/KBGZeYqkOp4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="flex mb-3 h-[267px] w-full rounded-3xl ">
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination]}
            className="flex mb-3 h-[267px] w-full rounded-3xl mySwiper"
          >
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ACt5gt3peTU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/R6Plo3sTvt8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/XdIbHvuznKc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/fapEosVlQlo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/a12AmIjIxOw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/XI0wGnua25I"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/P7yB4cyY3uo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/3txq9phsmBg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="flex mb-3 h-[267px] w-full rounded-3xl ">
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination]}
            className="flex mb-3 h-[267px] w-full rounded-3xl mySwiper"
          >
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/XyM9q-_QL-g"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/qjq9HjNv1t0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/xicrOlGfdMo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/AMqYH6GAzeY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/_FFm8yimCLM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/42ZHxGr4cEg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/uMM3AOXhWvQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/8_dikW_-eWs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/vXiKIQH4k98"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="flex mb-3 h-[267px] w-full rounded-3xl ">
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/zMWe5UX4oA4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/txrIYCTx7V4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/j3GKc_xoU7c"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                className="flex mb-3 h-[267px] w-full rounded-3xl "
                width="560"
                height="315"
                src="https://www.youtube.com/embed/6hWx1GOSxhY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default video;
