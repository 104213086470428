import React from "react";
import Me1 from "./components/Me1";
import { motion } from "framer-motion";
const Media = () => {
  const animations = {
    initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 },
  };
  return (
    <motion.div variants={animations}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{ duration: 0.4 }} className="bgbody3 py-16">
      <div className="">
        <Me1 />
      </div>
    </motion.div>
  );
};

export default Media;
