import React from 'react'
import MembreA from './components/MembreA'
import MembreB from './components/MembreB'
import { motion } from 'framer-motion'

const Members = () => {
  const animations = {
    initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 },
  };
  return (
    <motion.div variants={animations}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{ duration: 0.4 }} className="">
     <MembreB/>
     <MembreA/>
    </motion.div>
  )
}

export default Members