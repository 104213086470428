import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import logo from "../assets/img/logo_bd44dd10fa733d2ae526e648261f2ca8_1x.png";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Navbar = () => {
  const notify = () =>
    toast.success("La Langue Francais", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const notify1 = () =>
    toast.success("English Language", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const notify2 = () =>
    toast.success("اللغة العربية", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const { i18n } = useTranslation();
  function handleClick(lang) {
    i18n.changeLanguage(lang);
  }
  const { t } = useTranslation();
  return (
    <Disclosure as="nav" className="bg-zinc-800 shadow">
      {({ open }) => (
        <>
          <div className="w-screen bg-zinc-800 shadow mx-auto px-4 sm:px-6 lg:px-10 py-2 fixed z-50">
            <div className="flex justify-between h-16 ">
              <div className="flex ">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">ouvrir le menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <Link to="/" className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={logo}
                    alt="Home"
                  />
                  <img
                    className="hidden lg:block h-12 w-16"
                    src={logo}
                    alt="Home"
                  />
                </Link>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <Link
                    to="/Members"
                    className="border-transparent  text-white font-semibold hover:border-amber-700 hover:text-amber-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm"
                  >
                    <button className="">{t("Membres")}</button>
                  </Link>
                  <Link
                    to="/Media"
                    className="border-transparent text-white font-semibold hover:border-amber-700 hover:text-amber-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm"
                  >
                    <button className="">{t("Les Festivals")}</button>
                  </Link>
                  <Link
                    to="/Orchestres"
                    className="border-transparent text-white font-semibold hover:border-amber-700 hover:text-amber-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm"
                  >
                    <button className="">{t("Vidéos")}</button>
                  </Link>
                  <Link
                    to="/Trio"
                    className="border-transparent text-white font-semibold hover:border-amber-700 hover:text-amber-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm"
                  >
                    <button className="">{t("Trio")}</button>
                  </Link>
                  <Link
                    to="/Registration"
                    className="border-transparent text-white font-semibold hover:border-amber-700 hover:text-amber-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm"
                  >
                    <button className="">{t("Devenir Membre")} </button>
                  </Link>
                </div>
              </div>
              <div className="flex items-center">
                <a href="#EN" onClick={notify1}>
                  <button
                    className="mr-2 bg-white rounded-lg px-1 text-sm font-semibold"
                    onClick={() => handleClick("fr")}
                  >
                    En
                  </button>
                </a>
                <a href="#FR" onClick={notify}>
                  <button
                    className="mr-2 bg-white rounded-lg px-[6px] text-sm font-semibold"
                    onClick={() => handleClick("en")}
                  >
                    Fr
                  </button>
                </a>
                <a href="#AR" onClick={notify2}>
                  <button
                    className="mr-2 bg-white rounded-lg px-[6px] text-sm font-semibold"
                    onClick={() => handleClick("ar")}
                  >
                    Ar
                  </button>
                </a>
                <div className="hidden md:ml-4 gap-1 md:flex-shrink-0 md:flex md:items-center">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="http://www.facebook.com/asso4art"
                  >
                    <button
                      type="button"
                      className="bg-zinc-700 p-1 rounded-full text-blue-600 hover:text-gray-500 focus:outline-none"
                    >
                      <span className="sr-only">Facebook</span>
                      <FaFacebook className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="http://www.instagram.com/asso4art/?igshid=YmMyMTA2M2Y%3D"
                  >
                    <button
                      type="button"
                      className="bg-zinc-700 p-1 rounded-full text-pink-800 hover:text-gray-500 focus:outline-none"
                    >
                      <span className="sr-only">instagram</span>
                      <FaInstagram className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="http://www.youtube.com/channel/UC5yg8Ft377tcAkmj6Tfw2DA"
                  >
                    <button
                      type="button"
                      className="bg-zinc-700 p-1 rounded-full text-red-600 hover:text-gray-500 focus:outline-none"
                    >
                      <span className="sr-only">Youtube</span>
                      <FaYoutube className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden w-full bg-zinc-800 mt-20 fixed z-20">
            <div className="pt-2 pb-3 space-y-1 ">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
             
              <Link to="/Members">
                <Disclosure.Button
                  as="a"
                  className="border-transparent text-white hover:bg-amber-700 hover:border-amber-700 hover:text-amber-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                >
                  {t("Membres")}
                </Disclosure.Button>
              </Link>
              <Link to="/Media">
                <Disclosure.Button
                  as="a"
                  className="border-transparent text-white hover:bg-amber-700 hover:border-amber-700 hover:text-amber-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                >
                  {t("Les Festivals")}
                </Disclosure.Button>
              </Link>
              <Link to="/Trio">
                <Disclosure.Button
                  as="a"
                  className="border-transparent text-white hover:bg-amber-700 hover:border-amber-700 hover:text-amber-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                >
                  {t("Trio")}
                </Disclosure.Button>
              </Link>
              <Link to="/Orchestres">
                <Disclosure.Button
                  as="a"
                  className="border-transparent text-white hover:bg-amber-700 hover:border-amber-700 hover:text-amber-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                >
                  {t("Vidéos")}
                </Disclosure.Button>
              </Link>
              <Link to="/Registration">
                <Disclosure.Button
                  as="a"
                  className="border-transparent text-white hover:bg-amber-700 hover:border-amber-700 hover:text-amber-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                >
                  {t("Devenir Membre")}
                </Disclosure.Button>
              </Link>
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex mx-auto gap-4 items-center px-4 sm:px-6">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="http://www.facebook.com/asso4art"
                >
                  <button
                    type="button"
                    className="flex bg-zinc-700 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Facebook</span>
                    <FaFacebook
                      className="h-6 w-6 text-blue-500"
                      aria-hidden="true"
                    />
                  </button>
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="http://www.instagram.com/asso4art/?igshid=YmMyMTA2M2Y%3D"
                >
                  <button
                    type="button"
                    className="flex bg-zinc-700 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Instagram</span>
                    <FaInstagram
                      className="h-6 w-6 text-pink-600"
                      aria-hidden="true"
                    />
                  </button>
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="http://www.youtube.com/channel/UC5yg8Ft377tcAkmj6Tfw2DA"
                >
                  <button
                    type="button"
                    className="flex bg-zinc-700 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <FaYoutube
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </button>
                </a>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
