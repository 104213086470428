import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context/AuthContext";
import { Link } from "react-router-dom";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  orderBy,
  query,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage";
import { FaTrash, FaEdit } from "react-icons/fa";
import { storage, db } from "../firebase-config";
import { v4 } from "uuid";

const Memberad = () => {
  const { user, logout } = UserAuth();
  const [imageUpload, setImageUpload] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [imageUrls, setImageUrls] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [order, setOrder] = useState("");
  const [description, setDescription] = useState("");
  const [people, setPeople] = useState([]);
  const imagesListRef = ref(storage, "Bureau/");

  const uploadFileAndAddPerson = () => {
    if (imageUpload == null) return;

    const imageRef = ref(storage, `Bureau/${imageUpload.name + v4()}`);

    uploadBytes(imageRef, imageUpload)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          const newPerson = {
            name: name,
            role: role,
            description: description,
            order: order,
            imageUrl: url,
          };
          addDoc(collection(db, "people"), newPerson)
            .then(() => {
              console.log("Person added to Firestore!");
              setName("");
              setRole("");
              setDescription("");
              setOrder("");
              window.location.reload();
            })
            .catch((error) => {
              console.error("Error adding person to Firestore: ", error);
            });
        });
      })
      .catch((error) => {
        console.error("Error uploading image to Storage: ", error);
      });
  };

  const deletePerson = async (personId, imageUrl) => {
    console.log("imageUrl:", imageUrl);
    const imageRef = ref(storage, imageUrl);
    await deleteObject(imageRef);
    await deleteDoc(doc(db, "people", personId));
    window.location.reload();
  };

  useEffect(() => {
    listAll(imagesListRef)
      .then((response) => {
        response.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            setImageUrls((prev) => [...prev, url]);
          });
        });
      })
      .catch((error) => {
        console.error("Error listing images in Storage: ", error);
      });
  }, [imagesListRef]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDocs(
        query(collection(db, "people"), orderBy("order"))
      );
      setPeople(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
  }, []);
  const updatePerson = async (personId, updatedPersonData, updatedImage) => {
    try {
      let imageUrl = updatedPersonData.imageUrl;

      if (updatedImage != null) {
        // Upload the new image to Firebase Storage
        const imageRef = ref(storage, `Bureau/${updatedImage.name + v4()}`);
        await uploadBytes(imageRef, updatedImage);

        // Get the URL of the uploaded image
        imageUrl = await getDownloadURL(imageRef);

        // Delete the old image from Firebase Storage
        const oldImageRef = ref(storage, updatedPersonData.imageUrl);
        await deleteObject(oldImageRef);
      }

      // Update the person's data in Firestore with the new image URL
      const updatedPersonDataWithImage = { ...updatedPersonData, imageUrl };
      await updateDoc(doc(db, "people", personId), updatedPersonDataWithImage);
      console.log("Person updated in Firestore!");

      // Reload the page to show the updated data
      window.location.reload();
    } catch (error) {
      console.error("Error updating person in Firestore: ", error);
    }
  };

  const handleEditClick = (personId) => {
    // Get the person object based on the ID
    const personToUpdate = people.find((person) => person.id === personId);

    // Show a prompt to the user to enter the updated data
    const updatedName = prompt("Enter updated name:", personToUpdate.name);
    const updatedRole = prompt("Enter updated role:", personToUpdate.role);
    const updatedDescription = prompt(
      "Enter updated description:",
      personToUpdate.description
    );

    // Get the selected image file
    const updatedImage = document.getElementById("file-input").files[0];

    // Create an object with the updated data and selected image file
    const updatedPersonData = {
      name: updatedName || personToUpdate.name,
      role: updatedRole || personToUpdate.role,
      description: updatedDescription || personToUpdate.description,
      imageUrl: personToUpdate.imageUrl,
    };

    // Call the updatePerson function with the person ID, updated data, and selected image file
    updatePerson(personId, updatedPersonData, updatedImage);
  };

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text max-w-[350px] line-clamp-2">
        {isReadMore ? text.slice(0, 20) : text}
        <button
          onClick={toggleReadMore}
          className="read-or-hide text-zinc-500 hover:text-zinc-300"
        >
          {isReadMore ? "...Plus" : "...<=="}
        </button>
      </p>
    );
  };

  return (
    <div className="pt-20">
      <div class="bg-gray-900 min-h-screen flex items-center justify-center">
        <div class="bg-gray-800 flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 max-w-6xl sm:p-6 sm:my-2 sm:mx-4 sm:rounded-2xl">
          <div class="bg-gray-900 px-2 lg:px-4 py-2 lg:py-10 sm:rounded-xl flex lg:flex-col justify-between">
            <nav class="flex items-center flex-row space-x-2 lg:space-x-0 lg:flex-col lg:space-y-2">
              {/* home */}
              <Link to="/Account">
              <button
                class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 sm:h-6 sm:w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
              </button>
              
              </Link>
              {/* member */}
              <Link to="/Adminmembers">
              <button
                class="bg-gray-800 text-white p-4 inline-flex justify-center rounded-md"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 sm:h-6 sm:w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                </svg>
              </button>

              </Link>
              {/* events */}
              <Link to="/Events">
              <button
                class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 sm:h-6 sm:w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M15,4h-1V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3v1H5a3,3,0,0,0-3,3v9a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V7A3,3,0,0,0,15,4ZM6,7A1,1,0,0,1,7,6h6a1,1,0,0,1,1,1v2.5H6Zm9,9a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11.5h11Zm0-7H4V8A1,1,0,0,1,5,7h8a1,1,0,0,1,1,1Z" />
                  <path d="M10,13a2,2,0,1,0,2,2A2,2,0,0,0,10,13Zm0,3a1,1,0,1,1,1-1A1,1,0,0,1,10,16Z" />
                </svg>
              </button>
              </Link>
            </nav>
            <div class="flex items-center flex-row space-x-2 lg:space-x-0 lg:flex-col lg:space-y-2">
              {/* logout */}
              <button
                onClick={logout}
                class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 sm:h-6 sm:w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="flex-1 px-2 sm:px-0">
            <div class="flex justify-between items-center">
              <h3 class="text-3xl font-extralight text-white">
                {user && user.email}
              </h3>
            </div>

            <div>
              <div className="flex flex-col items-center">
                <div className="max-w-md w-full space-y-8">
                  <div className="flex flex-col items-center">
                    <input
                      className="text-sm text-grey-500 mr-5 py-3 px-10 rounded-full border-0 text-md font-semibold text-white bg-gradient-to-r from-blue-600 to-amber-600 hover:cursor-pointer hover:opacity-80"
                      type="file"
                      onChange={(event) => {
                        setImageUpload(event.target.files[0]);
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full border rounded-md px-3 py-2 mt-4 text-sm"
                    />
                    <input
                      type="text"
                      placeholder="Role"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      className="w-full border rounded-md px-3 py-2 mt-4 text-sm"
                    />
                    <input
                      type="text"
                      placeholder="order"
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                      className="w-full border rounded-md px-3 py-2 mt-4 text-sm"
                    />
                    <textarea
                      placeholder="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="w-full border rounded-md px-3 py-2 mt-4 text-sm resize-y"
                    />
                    <button
                      className="font-bold mt-2 shadow-md rounded-full bg-gradient-to-r from-indigo-500 via-pink-500 to-yellow-500 hover:from-indigo-600 hover:via-pink-600 hover:to-red-600 px-4 py-3 text-white outline-none focus:ring-4 transform active:scale-x-75 transition-transform mx-5 flex"
                      onClick={uploadFileAndAddPerson}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                      Upload and Add Person
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <table className="w-full text-white mx-auto my-8 border-collapse">
                  <thead>
                    <tr className="bg-gray-100 border-b-2 border-gray-300 text-left">
                      <th className="py-3 px-4 font-semibold text-gray-700 text-center">
                        Name
                      </th>
                      <th className="py-3 px-4 font-semibold text-gray-700 text-center">
                        Description
                      </th>
                      <th className="py-3 px-4 font-semibold text-gray-700 text-center">
                        Role
                      </th>
                      <th className="py-3 px-4 font-semibold text-gray-700">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {people.map((person) => (
                      <tr
                        key={person.id}
                        className="border-b-2 border-gray-300"
                      >
                        <td className="py-2 px-4 max-w-xs overflow-hidden whitespace-normal break-words">
                          {person.name}
                        </td>
                        <td className="py-2 px-4 max-w-xs overflow-hidden whitespace-normal break-words">
                          <ReadMore>{person.description}</ReadMore>
                        </td>
                        <td className="py-2 px-4 max-w-xs overflow-hidden whitespace-normal break-words">
                          {person.role}
                        </td>
                        <td className="py-2 px-4 flex justify-center space-x-4">
                          <button
                            onClick={() =>
                              deletePerson(person.id, person.imageUrl)
                            }
                            className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
                          >
                            <FaTrash />
                          </button>
                          <div class="flex items-center">
                            <input
                              id="file-input"
                              type="file"
                              class="hidden"
                              accept=".jpg,.JPG,.jpeg,.PNG"
                            />
                            <label
                              for="file-input"
                              class="cursor-pointer bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                            >
                              <svg
                                class="w-4 h-4 mr-2"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 17h14V5H5v12zM7 7h10v2H7V7zm0 4h7v2H7v-2z"
                                  fill="currentColor"
                                />
                              </svg>
                            </label>
                          </div>
                          <button
                            className="text-blue-500 hover:text-blue-700 focus:outline-none"
                            onClick={() => handleEditClick(person.id)}
                          >
                            <FaEdit />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {people.length < 1 ? null : (
                  <p className="text-center text-white p-2">{`You have ${people.length} Member(s)`}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Memberad;