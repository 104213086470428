import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "./Context/AuthContext";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signIn(email, password);
      navigate("/account");
    } catch (e) {
      setError("There is no user with this email.");
      console.log(e.message);
    }
  };

  return (
    <div className="pt-20">
      <div className="max-w-[700px] mx-auto my-16 p-4">
        <form onSubmit={handleSubmit}>
          {error && (
            <div className="text-red-500 text-center mb-4">{error}</div>
          )}
          <p class="mb-4">Please login to your account</p>
          <div class="mb-4">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="exampleFormControlInput1"
              placeholder="Email"
            />
          </div>
          <div class="mb-4">
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Password"
            />
          </div>
          <div class="text-center pt-1 mb-12 pb-1">
            <button className='border border-blue-500 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white'>
              Sign In
            </button>
            <a class="text-gray-500" href="#!">
              Forgot password?
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signin;

