import React from "react";
import { UserAuth } from "../Context/AuthContext";
import { Link } from "react-router-dom";
import AnimatedText from "react-animated-text-content";
import { useTranslation } from "react-i18next";

const Account = () => {
  const { user, logout } = UserAuth();
  const { t } = useTranslation();

  return (
    <div className="pt-20">
      <div class="bg-gray-900 min-h-screen flex items-center justify-center">
        <div class="bg-gray-800 flex-1 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-10 max-w-6xl sm:p-6 sm:my-2 sm:mx-4 sm:rounded-2xl">
          <div class="bg-gray-900 px-2 lg:px-4 py-2 lg:py-10 sm:rounded-xl flex lg:flex-col justify-between">
            <nav class="flex items-center flex-row space-x-2 lg:space-x-0 lg:flex-col lg:space-y-2">
              {/* home */}
              <Link to="/Account">
                <button class="bg-gray-800 text-white p-4 inline-flex justify-center rounded-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 sm:h-6 sm:w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </button>
              </Link>
              {/* member */}
              <Link to="/Adminmembers">
                <button class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 sm:h-6 sm:w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                  </svg>
                </button>
              </Link>
              {/* events */}
              <Link to="/Events">
                <button class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 sm:h-6 sm:w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M15,4h-1V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3v1H5a3,3,0,0,0-3,3v9a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V7A3,3,0,0,0,15,4ZM6,7A1,1,0,0,1,7,6h6a1,1,0,0,1,1,1v2.5H6Zm9,9a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11.5h11Zm0-7H4V8A1,1,0,0,1,5,7h8a1,1,0,0,1,1,1Z" />
                    <path d="M10,13a2,2,0,1,0,2,2A2,2,0,0,0,10,13Zm0,3a1,1,0,1,1,1-1A1,1,0,0,1,10,16Z" />
                  </svg>
                </button>
              </Link>
            </nav>
            <div class="flex items-center flex-row space-x-2 lg:space-x-0 lg:flex-col lg:space-y-2">
              {/* logout */}
              <button
                onClick={logout}
                class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 sm:h-6 sm:w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="flex-1 px-2 sm:px-0">
            <div class="flex justify-between items-center">
              <h3 class="text-3xl font-extralight text-white">
                {user && user.email}
              </h3>
            </div>

            <div>
              <div className="flex flex-col mt-5 items-center">
                <div className=" w-full lg:w-full space-y-8">
                  <div className="relative px-4  sm:px-6  lg:px-8">
                    <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                      <span className="block text-white">
                        <AnimatedText
                          type="words" // animate words or chars
                          animation={{
                            x: "200px",
                            y: "-20px",
                            scale: 1.1,
                            ease: "ease-in-out",
                          }}
                          animationType="float"
                          interval={0.06}
                          duration={1}
                          tag="p"
                          className="animated-paragraph"
                          includeWhiteSpaces
                          threshold={0.1}
                          rootMargin="20%"
                        >
                          {t("Association Quatrième Art")}
                        </AnimatedText>
                      </span>
                    </h1>
                    <h3 className="text-center block text-indigo-200">
                      <AnimatedText
                        type="words" // animate words or chars
                        animation={{
                          x: "200px",
                          y: "-20px",
                          scale: 1.1,
                          ease: "ease-in-out",
                        }}
                        animationType="float"
                        interval={0.06}
                        duration={2}
                        tag="p"
                        className="animated-paragraph"
                        includeWhiteSpaces
                        threshold={0.1}
                        rootMargin="20%"
                      >
                        "Bienvenue dans votre compte d'administrateur ! Nous
                        sommes heureux de vous accueillir ici. Votre compte
                        d'administration vous permettra de gérer et de contrôler
                        différents aspects de votre entreprise ou organisation,
                        en fonction de vos autorisations. Que vous soyez ici
                        pour gérer les utilisateurs, les ressources, les
                        finances ou toute autre fonctionnalité, nous sommes là
                        pour vous aider à chaque étape. Notre équipe est
                        disponible pour répondre à toutes vos questions et vous
                        aider à tirer le meilleur parti de votre compte
                        d'administration. N'hésitez pas à explorer toutes les
                        fonctionnalités que nous offrons et à nous faire part de
                        vos commentaires ou suggestions. Nous sommes toujours à
                        la recherche de moyens d'améliorer notre plateforme pour
                        répondre aux besoins de nos utilisateurs. Merci
                        d'utiliser notre service et bonne journée !"
                      </AnimatedText>
                    </h3>
                  </div>
                </div>
                  <p className="text-white/50 mb-4">
                    <Link to="/Adminmembers">
                      <button class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 sm:h-6 sm:w-6"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                        </svg>
                      </button>
                    </Link>
                    Cette icône vous permet d'ajouter de nouveaux membres ou de
                    modifier les membres existants. Vous pouvez utiliser cette
                    fonctionnalité pour gérer les utilisateurs de votre compte,
                    ajouter des collaborateurs à votre équipe ou modifier les
                    autorisations d'accès pour les membres existants. Cliquez
                    sur cette icône pour accéder à la page de gestion des
                    utilisateurs et commencer à ajouter ou modifier les membres
                    de votre organisation.
                  </p>
                  <p className="mt-2 text-white/50">
                  <Link to="/Events">
              <button
                class="text-white/50 p-4 inline-flex justify-center rounded-md hover:bg-gray-800 hover:text-white smooth-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 sm:h-6 sm:w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M15,4h-1V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3v1H5a3,3,0,0,0-3,3v9a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V7A3,3,0,0,0,15,4ZM6,7A1,1,0,0,1,7,6h6a1,1,0,0,1,1,1v2.5H6Zm9,9a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11.5h11Zm0-7H4V8A1,1,0,0,1,5,7h8a1,1,0,0,1,1,1Z" />
                  <path d="M10,13a2,2,0,1,0,2,2A2,2,0,0,0,10,13Zm0,3a1,1,0,1,1,1-1A1,1,0,0,1,10,16Z" />
                </svg>
              </button>
              </Link>
              Cette icône vous permet de gérer vos événements. Vous pouvez utiliser cette fonctionnalité pour créer de nouveaux événements, modifier les événements existants ou supprimer des événements. Vous pouvez également visualiser les détails de chaque événement, tels que les dates, les horaires, les emplacements et les descriptions, ainsi que gérer les inscriptions et les billets. Cliquez sur cette icône pour accéder à la page de gestion des événements et commencer à créer et gérer vos événements.
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
