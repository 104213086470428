import React from 'react'

const trioim = () => {

    return (
      

        <div class="mt-16">
            <div className="grid grid-cols-1 md:grid-cols-3 z-10 relative py-14 lg:px-8">
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr1.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr2.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr3.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr4.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr5.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr6.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr7.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr8.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr9.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr10.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr11.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                <div className='px-4'>
                <img
                    src={require("../assets/trio1/tr12.jpeg")}
                    alt=""
                    className="object-cover flex h-[250px] w-full rounded-3xl mb-3 md:mb-10"
                />
                </div>
                
               
            </div>
            </div>


    )
}

export default trioim