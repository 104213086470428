import React from "react";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const Atte1 = () => {
  const { params } = useParams();
  useEffect(() => {
    handleClick();
    // eslint-disable-next-line
  });

  const handleClick = () => {
    let url = `http://alfan4.com/static/media/${params}`;
    saveAs(url, "Attestation");
  };
  return (
    <>
      <ul>
        <div class="flex items-center justify-center pt-20 w-screen h-screen bg-white dark:bg-gray-800">
          <div class="bg-white dark:bg-gray-800 text-black dark:text-gray-200 p-4 antialiased flex max-w-lg">
            <img
              class="rounded-full h-8 w-8 mr-2 mt-1 "
              src={require("../assets/img/ADL_2531-2.jpg")}
              alt="."
            />

            <div>
              <div class="bg-gray-100 dark:bg-gray-700 rounded-3xl px-4 pt-9 pb-2.5">
                <div class="font-semibold  text-sm text-blue-500 leading-relaxed mb-3">Président Houssine Boufettal</div>
                <div class="text-normal leading-snug md:leading-normal">
                Chère Madame / Cher Monsieur,
                  <br /> Le "19-25 Décembre" dernier, nous avons organisé le
                  "Festival lnternational de Luth" auquel vous nous avez honoré
                  de votre participation. <br /> Je tenais à vous remercier
                  personnellement pour avoir assisté à cet événement de grande
                  ampleur. <br /> Grâce à votre présence, les participants se
                  sont sentis soutenus comme jamais... <br /> Outre le plaisir
                  de vous rencontrer et de vous avoir à nos côtés ce jour-là,
                  votre présence a été un encouragement et une véritable
                  récompense. <br /> Avec encore une fois ma plus vive
                  reconnaissance, je vous prie de croire,
                  <br /> chère Madame / cher Monsieur, en l'expression de mes
                  sentiments les plus dévoués.
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </ul>
    </>
  );
};

export default Atte1;
