import React from "react";
import { motion } from "framer-motion";
import Video from "./components/Video";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Orchestres = () => {
  const { t } = useTranslation();

  const animations = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
  };
  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.4 }}   className="w-full h-full object-cover "

    >
      
      <div className="px-4 lg:px-0 py-10">
      
        <div>
          <Video />
          <div className="">
            <Link
              to="/"
              className="flex w-full md:w-3/12 md:mx-auto items-center justify-center mt-7 mb-4 px-3 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-gray-800 bg-yellow-300 hover:bg-opacity-10 hover:text-white sm:px-8"
              >{t("Autre Vidéos")}
            </Link>
            </div>
        </div>
      
      </div>
    </motion.div>
  );
};

export default Orchestres;
